<template>
  <v-app id="viewpoint">
    <v-main>
      <v-row class="login-wrapper">
        <v-col
          cols="12"
          sm="4"
          md="5"
          lg="5"
          xl="5"
          class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
          v-bind:style="leftPanelStyle"
        ></v-col>
        <v-col>
          <v-row class="login-wrapper" align="center" justify="center">
            <v-col cols="8">
              <img alt :src="mainLogo" width="200" class="mb-9" />
              <h1>Forgotten your password?</h1>
              <p class="mb-7">
                Please enter your email address and follow the instructions
                that'll be sent to you
              </p>
              <v-alert type="success" v-if="success"
                >Success! You should receive an email in your inbox in the next
                few minutes with further instructions on how to reset your
                password</v-alert
              >
              <v-form
                @submit.prevent="requestResetPassword"
                method="post"
                class="mb-3"
                v-if="!success"
              >
                <v-alert type="error" v-if="hasError"
                  >Sorry, something went wrong when attempting to request a new
                  password for you account. Please check your details and try
                  again</v-alert
                >
                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="email"
                  v-model="email"
                ></v-text-field>
                <v-btn
                  color="primary"
                  type="submit"
                  block
                  large
                  :loading="this.$store.getters.showLoader"
                  >Send Password Reset Email</v-btn
                >
              </v-form>
              <p class="caption">
                <router-link :to="{ name: 'login' }"
                  >Return to login</router-link
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },

  data() {
    return {
      email: null,
      hasError: false,
      success: false,
    };
  },

  computed: {
    leftPanelStyle() {
      return {
        backgroundImage: `url('${window.location.origin}/img/${this.$store.getters.brand.background}')`,
        backgroundColor: "#212121",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    },
    mainLogo() {
      return `${window.location.origin}/img/${this.$store.getters.brand.logo}`;
    },
  },

  methods: {
    requestResetPassword() {
      const email = this.email;
      this.$store
        .dispatch("resetPasswordRequest", { email })
        .then(() => {
          this.email = null;
          this.hasError = false;
          this.success = true;
        })
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>

<style>
.login-wrapper {
  height: 100%;
}
</style>
